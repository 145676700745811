export const games = [
  {
    id: "TheFirstSimulation",
    storyUrl:
      "https://gl.githack.com/-/snippets/2494627/raw/main/TheFirstSimulation.json",
    title: "The First Simulation",
    imageUrl:
      "https://live.staticflickr.com/65535/52748961114_714d1a685c_b.jpg",
    firstState: "The First Simulation",
    description:
      "In the metaverse O-380, Sidzi is a rarity – a natural-born child of two legendary icons. Raised by her guardian AL-Y, she navigates life in a world of instantiated identities while grappling with her parents' legacies. On her 12th birthday, Sidzi reconnects with a cherished folktale.",
  },
  {
    id: "NotSafeForWork",
    storyUrl:
      "https://gl.githack.com/-/snippets/2498274/raw/main/NotSafeForWork.json",
    title: "Not Safe for Work",
    imageUrl:
      "https://live.staticflickr.com/65535/52748714581_b62d2b8c08_b.jpg",
    firstState: "Not Safe For Work",
    description:
      "Caught in the web of metaverse espionage, artificial bounty hunter M3la faces a harrowing dilemma when confronted by the enigmatic 'Snatchers.' As the stakes rise, M3la must grapple with questions of trust, identity, and the ultimate price of her own existence.",
  },
  {
    id: "BlastFromThePast",
    storyUrl:
      "https://gl.githack.com/-/snippets/2509214/raw/main/BlastFromThePast.json",
    title: "Blast From The Past",
    imageUrl:
      "https://live.staticflickr.com/65535/52749127200_71c4ddae50_b.jpg",
    firstState: "Blast From The Past",
    description:
      "In a world of interconnected metaverses, Zera and her ancient boyfriend Po embark on a mysterious hike to uncover a hidden truth. As they trek through a beautiful landscape, they confront their beliefs and face the reality of a forbidden past that could change their lives forever. Will they risk everything for the truth or choose to live a comfortable lie?",
  },
  {
    id: "FragmentsOfFreedom",
    storyUrl:
      "https://gl.githack.com/-/snippets/2534679/raw/main/FragmentsOfFreedom.json",
    imageUrl:
      "https://live.staticflickr.com/65535/52855445333_c672f27c72_b.jpg",
    title: "Fragments of Freedom",
    firstState: "Fragments of Freedom",
    description:
      "In a dystopian future where knowledge is suppressed, and simulations blur the line between reality and illusion, Po embarks on a journey through the enigmatic Library of Alexandria. As he uncovers long-lost stories of legendary figures and explores the vivid exhibitions, he discovers the power of truth and the importance of freedom. Delve into a world where the fight for knowledge transcends the physical realm and challenges the very essence of being.",
  },
  {
    id: "TheHitchhikersGuide",
    storyUrl:
      "https://gl.githack.com/-/snippets/2534679/raw/main/FragmentsOfFreedom.json",
    imageUrl:
      "https://i.ibb.co/bR7wFxt/DALL-E-2025-01-19-11-54-58-A-vibrant-and-surreal-book-cover-for-a-humorous-sci-fi-story-titled-The-H.webp",
    title: "The Hitchhiker's Guide",
    description:
      "In a bizarrely entangled corner of the multiverse, Harvey, an ordinary NPC blissfully unaware of his simulated reality, finds his world upended when a brash, larger-than-life stranger barges into his life. What follows is a chaotic romp through surreal landscapes and existential riddles as Harvey is thrust into an adventure he neither understands nor particularly wants. Along the way, he encounters eccentric characters, impossible technology, and hints at his own unique importance in the vast web of metaverses. Unbeknownst to him, his journey is just beginning—and it’s about to redefine what it means to exist.",
    isMarkdown: true,
    markdownLocation: "./stories/TheHitchhikersGuide.md",
  },
  {
    id: "TheMetaverseImagined",
    storyUrl:
      "https://gl.githack.com/-/snippets/2534988/raw/main/Illustrations.json",
    imageUrl:
      "https://nostr.build/i/b0a07d8436e2f6207038e86905432ad40801ffa6ba82646f197bb7b721b19877.jpg",
    title: "The Metaverse Imagined",
    firstState: "The Metaverse Imagined",
    description: "Art contributed by fellow Metaversal Beings.",
  },
];
