import React, { useRef, useState, useEffect } from "react";

type AutoButtonProps = {
  delay: number;
  onClick: () => void;
  text: string;
};

function AutoClickButton(props: AutoButtonProps) {
  // Create a reference to the button element
  const buttonRef = useRef(null);

  // Use state to store the remaining time before the button is clicked
  const [remainingTime, setRemainingTime] = useState(props.delay || 5);

  // Use state to store the interval ID
  const intervalId = useRef<NodeJS.Timer>();

  // Use state to store whether the auto-click feature is enabled
  const [autoClickEnabled, setAutoClickEnabled] = useState(true);

  // Set an interval to update the remaining time every second
  useEffect(() => {
    if (autoClickEnabled) {
      const interval: NodeJS.Timer = setInterval(() => {
        setRemainingTime((prevRemainingTime: number) => prevRemainingTime - 1);
      }, 1000);
      intervalId.current = interval;
    }

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => {}; //clearInterval(intervalId.current);
  }, [autoClickEnabled, intervalId, setRemainingTime]);

  // Set a delay before calling the onClick callback
  useEffect(() => {
    if (remainingTime === 0) {
      props.onClick();
    }
  }, [remainingTime, props]);

  // Add a "click to cancel" feature to the button
  const handleButtonClick = () => {
    // Cancel the auto-click feature
    setAutoClickEnabled(false);

    // Clear the interval
    //clearInterval(intervalId.current);

    // Reset the remaining time to the original delay value
    setRemainingTime(props.delay || 5);

    // Only call the onClick callback if the auto-click feature is disabled
    if (!autoClickEnabled) {
      props.onClick();
    }
  };

  return (
    <button
      ref={buttonRef}
      className="play-game-button matrix-style"
      onClick={handleButtonClick}
    >
      {autoClickEnabled
        ? `${props.text} in ${remainingTime} seconds. Click to cancel.`
        : props.text}
    </button>
  );
}

export default AutoClickButton;
