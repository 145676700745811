import React, { useState, useEffect } from "react";
import ContentDisplayer from "./components/ContentDisplayer";
import ReactMarkdown from "react-markdown";
import {
  fetchGameStory,
  checkPrerequisites,
  detectMob,
} from "./utils/utils.js";
import Loader from "./components/Loader";
import OptionsMenu from "./components/OptionsMenu";
import { useParams } from "react-router";
import { games } from "./GameData";
import { useNavigate } from "react-router-dom";

function getStateTitle(text) {
  return "# " + text;
}

function Game() {
  const [gameMetaData, setGameMetaData] = useState(null);

  const [gameStory, setGameStory] = useState(null);
  const [currentStateId, setCurrentStateId] = useState(null);
  const [renderOptions, setRenderOptions] = useState(false);
  const [visitedStates, setVisitedStates] = useState([]);
  const [items, setItems] = useState([]);
  const [bitcoin, setBitcoin] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [markdownContent, setMarkdownContent] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const findGameDataById = (id) => {
    return games.find((game) => game.id === id);
  };

  useEffect(() => {
    if (!gameMetaData) {
      const result = findGameDataById(id);
      setGameMetaData(result);
      if (!result.isMarkdown)
        fetchGameStory(setProgress, result.storyUrl).then((gameDataJson) => {
          setGameStory(gameDataJson);
          setCurrentStateId(result.firstState);
          setLoaded(true);
        });
      else {
        // Fetch markdown content
        fetch(result.markdownLocation)
          .then((response) => response.text())
          .then((content) => {
            setMarkdownContent(content);
            setLoaded(true);
          })
          .catch((err) => {
            console.error("Error fetching markdown content:", err);
          });
      }
    }
  }, [gameMetaData, id]);

  function handleRestartClick() {
    setCurrentStateId(gameMetaData.firstState);
    setVisitedStates([]); // Clear the visitedStates array
  }

  function handleBackClick() {
    setVisitedStates(visitedStates.slice(0, -1));
    setCurrentStateId(visitedStates[visitedStates.length - 1]);
  }

  async function handleButtonClick(option) {
    if (!visitedStates.includes(currentStateId)) {
      setVisitedStates([...visitedStates, currentStateId]);
    }
    if (option.addItem) {
      setItems([...items, option.addItem]);
    }
    if (option.addBitcoin) {
      setBitcoin(bitcoin + option.addBitcoin);
    }
  }

  async function onContinueClick(option) {
    setRenderOptions(false);
    setCurrentStateId(option.nextState);
  }

  async function handleTextRendered() {
    setTimeout(() => {
      setRenderOptions(true);
    }, 2000);
  }

  function renderCurrentState() {
    if (!gameStory || !currentStateId) return null;

    const currentState = gameStory.states.find(
      (state) => state.id === currentStateId
    );
    if (currentState) {
      var displayText = checkPrerequisites(
        currentState.text_prerequisites,
        visitedStates,
        items,
        bitcoin
      );
    }
    if (!currentState) {
      return (
        <>
          <p className="intro-text">
            There's a Glitch in this simulation!.. Fixing it!
          </p>
          <button
            className="play-game-button matrix-style"
            onClick={handleRestartClick}
          >
            Restart
          </button>
          <button
            className="play-game-button matrix-style"
            onClick={handleBackClick}
          >
            Back
          </button>
        </>
      );
    }
    return (
      <>
        <div
          className="center"
          style={{
            overflow: "scroll",
            height: "70%",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "5%",
            paddingBottom: "10%",
          }}
        >
          <span className="intro-text">
            <ReactMarkdown children={getStateTitle(currentState.id)} />
          </span>
          {displayText && (
            <span className="intro-text">
              <ContentDisplayer
                linesPerPage={detectMob() ? 3 : 8}
                onFinish={handleTextRendered}
                state={currentState}
              />
            </span>
          )}
        </div>
        {(!displayText || renderOptions) && (
          <OptionsMenu
            options={currentState.options}
            onClick={handleButtonClick}
            visitedStates={visitedStates}
            items={items}
            bitcoin={bitcoin}
            onContinueClick={(option) => {
              onContinueClick(option);
            }}
            onBackClick={() => {
              handleBackClick();
            }}
            onRestartClick={() => {
              navigate("/");
            }}
          />
        )}
      </>
    );
  }

  function renderMarkdown() {
    console.log("rendering markdown", markdownContent);
    return (
      <div
        className="markdown-container"
        style={{
          padding: "10%",
          overflowY: "scroll",
          height: "70%",
        }}
      >
        <span className="intro-text">
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </span>
      </div>
    );
  }

  return (
    <>
      {!loaded && (
        <div>
          <Loader percentage={progress} /> : null
        </div>
      )}
      {loaded && (
        <div>
          {!findGameDataById(id).isMarkdown
            ? renderCurrentState()
            : renderMarkdown()}
        </div>
      )}
    </>
  );
}

export default Game;
